// @import url(https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css);
@import "flex.scss";


form :global(.fields) :global(.fields_control){
  span{  
    padding-left: 5px; 
    padding-right: 5px;
    display: inline-block;
  }
  padding-right: 10px;
  
  // content: attr(data-container-height);
}

// For label border to work as exptected
form :global(.fields_label) label{
  display: inline-block;
}


// CSS for expiration style - input type
form input:global(#expiration_month),
form input:global(#expiration_year){
  width: 30px;
}

//CSS for CC field - based on expiration field style type
form :global(:not(.TEXTBOX)) :global(.fields:nth-child(1)) {
  input {
    //background-color: green;
    width: 290px; //with drop down on expiration fields
  }
}

form :global(.TEXTBOX) :global(.fields:nth-child(1)) {
  input {
    //background-color: green;
    width: 245px; // with input on expiration
  }
}


form.label_top :global(.fields:nth-child(1)) {
  :global(.fields_control) {
  }
}
form.label_top :global(.fields:nth-child(2)) {
  display: inline-block;
}
form.label_top :global(.fields:nth-child(3)) {
  display: inline-block;
}


// note that the expiration has 2 controls so adjusting the width
// will change for both equally. there is no way to make them different.
form.label_inline :global(.fields) {

  /* padding can be overriden by customizing style padding bottom */
  padding-bottom: 5px;
  * {
    @include inline-flex;
    white-space: nowrap;
  }
  :global(.fields_label) {
      @include justify-content(flex-end);
      /* label container width can be overriden by customizing style */
      width: 200px;
  }
  :global(.fields_control) {
      margin-left: 10px;
  }
}


form.label_bottom :global(.fields) {
  /* padding can be overriden by customizing style padding bottom */
  padding-bottom: 5px;
  @include flexbox;
  @include flex-dir(column-reverse);
  * {
    white-space: nowrap;
  }
  :global(.fields_control) {
      @include inline-flex;
  }
}


form.label_top_expanded :global(.fields) {
    /* padding can be overriden by customizing style padding bottom */
    padding-bottom: 5px;
    * {
      white-space: nowrap;
    }
    :global(.fields_control) {
        @include inline-flex;
    }
}


form.label_none :global(.fields) {
    /* padding can be overriden by customizing style padding bottom */
    padding-bottom: 5px;
    * {
      white-space: nowrap;
    }
    :global(.fields_label) {
        display: none;
    }
    :global(.fields_control) {
        @include inline-flex;
    }
}
