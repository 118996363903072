/*
Cross-browser Flexbox mixins

Set of css mixins for flexbox styles.
Fallbacks using 2009 'box' syntax (FF and Older WebKit) 
and prefixed syntaxes (IE10, WebKit browsers without flex wrapping).
Final standards syntax (FF, Safari, Chrome, IE11+, Edge, Opera).
*/

// Flexbox containers
// Using the display property's flex value causes an element to 
// generate a block-level flex container box. 
// The inline-flex value causes an element to generate an 
// inline-level flex container box.
// Values: flex | inline-flex
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// Flexbox direction
// Values: row (default) | row-reverse | column | column-reverse
@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
// Shorter syntax:
@mixin flex-dir($args...) { @include flex-direction($args...); }

// Flexbox wrap
// Values: nowrap (default) | wrap | wrap-reverse
@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

// Flexbox flow (shorthand)
// The flex-flow property is shorthand for setting 
// the flex-direction and flex-wrap properties, 
// which together define the flex container's main and cross axes.
// Values: row (default) | nowrap
@mixin flex-flow($values: (row nowrap)) {
  // No Webkit/FF Box fallback.
  -webkit-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

// Flexbox order
// The order property controls the order in which flex items appear 
// within their flex container, by assigning them to ordinal groups.
// Value: any integer (0 is the default)
@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -moz-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

// Flexbox grow
// sets the flex grow factor. Negative numbers are invalid.
@mixin flex-grow($int: 1) {
  -webkit-box-flex: $int;
  -moz-box-flex: $int;
  -webkit-flex-grow: $int;
  -ms-flex: $int;
  flex-grow: $int;
}


// Flexbox shrink
// sets the flex shrink factor. Negative numbers are invalid.
@mixin flex-shrink($int: 0) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex: $int;
  flex-shrink: $int;
}

// Flexbox basis
// sets the flex basis. Negative lengths are invalid.
// Values: See the flex-basis reference (the default is auto)
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}

// Flexbox "flex" (shorthand)
// The flex property specifies the components of a flexible length; 
// flex-grow factor, flex-shrink factor, and the flex-basis
@mixin flex($fg: 1, $fs: 0, $fb: auto) {

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so lets grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box: $fg-boxflex;
  -moz-box: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

// Flexbox justify content
// Values: flex-start (default) | flex-end | center | space-between | space-around
// note start-* not available in older syntax
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}
  // Shorter syntax:
@mixin flex-just($args...) { @include justify-content($args...); }

// Flexbox align items
// Values: flex-start | flex-end | center | baseline | stretch (default)
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}

// Flexbox align self
// Values: auto (default) | flex-start | flex-end | center | baseline | stretch
@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

// Flexbox align content
// Values: flex-start | flex-end | center | space-between | space-around | stretch (default)
@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
} 
